export const metamorphoses = {
  photos: [
    {
      src: "../img/metamorphoses/twarz_1.jpg",
      width: 1,
      height: 1,
    },
    {
      src: "../img/metamorphoses/twarz_2.jpg",
      width: 1,
      height: 1,
    },
    {
      src: "../img/metamorphoses/usta_1.jpg",
      width: 1,
      height: 1,
    },
    {
      src: "../img/metamorphoses/usta_2.jpg",
      width: 1,
      height: 1,
    },
    {
      src: "../img/metamorphoses/twarz_3.jpg",
      width: 1,
      height: 1,
    },
    {
      src: "../img/metamorphoses/twarz_4.jpg",
      width: 1,
      height: 1,
    },
    {
      src: "../img/metamorphoses/twarz_5.jpg",
      width: 1,
      height: 1,
    },
    {
      src: "../img/metamorphoses/twarz_6.jpg",
      width: 1,
      height: 1,
    },
    {
      src: "../img/metamorphoses/czolo_1.jpg",
      width: 1,
      height: 1,
    },
    {
      src: "../img/metamorphoses/czolo_2.jpg",
      width: 1,
      height: 1,
    },
  ],
}
