export const team = {
  members: [
    {
      title:
        "Dr n. med. Luiza Balicka Adamik - właścicielka kliniki, specjalista neurologii, dyplomowany lekarz medycyny estetycznej",
      description:
        "wieloletnie doświadczenie z dziedziny neurologii, Kierownik Oddziału Neurologii z Pododdziałem leczenia udarów Szpitala Wojewódzkiego w Przemyślu, certyfikat EEG, specjalista w leczeniu padaczek i chorób zwyrodnieniowych mózgu, kilkuletnie doświadczenie w leczeniu toksyną botulinową spastyczności poudarowej, dystonii, nadpotliwości, bruksizmu oraz migreny przewlekłej, współautorka kilkunastu publikacji w tym kilku w międzynarodowych czasopismach. Od kilku lat z pasją kształci się i odnosi sukcesy w nowej dziedzinie jaką jest Medycyna Estetyczna. W 2024 ukończyła studia podyplomowe w Hiszpanii z medycyny estetycznej uzyskując międzynarodowy dyplom - Master's Accreditation in Aesthetic Medicine, Nutrition and Anti-aging. Pani doktor specjalizuje się w terapiach łączonych (kwas hialuronowy, stymulatory, nici oraz urządzenia Hi-tech) leczeniu powikłań i laseroterapii. Zabiegi wykonywane są pod kontrolą USG.",
      imgLink: "../img/team/luiza.jpg",
    },
    {
      title: "Rimma Zaleszczyk - specjalista do spraw podologii",
      description:
        "specjalista do spraw podologii, technik usług kosmetycznych, trener, szkoleniowiec, od wielu lat związana z Europejskim Instytutem Podologicznym. Obecnie prowadzi własną firmę w Przemyślu. W Balicka Clinic - Pani Rimma profesjonalnie i holistycznie dba o stopy naszych pacjentów.",
      imgLink: "../img/team/rimma.jpg",
    },
    {
      title:
        "Kamila Jaworska - specjalista dermatolog i wenerolog, członek Polskiego Towarzystwa Dermatologicznego",
      description:
        "zajmuje się leczeniem i diagnostyką ostrych i przewlekłych chorób skóry (m.in. infekcje wirusowe, bakteryjne, grzybicze, AZS, łuszczyca, trądzik pospolity i różowaty, choroby autoimmunologiczne), schorzeń z kręgu wenerologii, a także wykonuje dermatoskopię znamion melanocytowych i zmian niemelanocytowych.",
      imgLink: "../img/team/kamila.jpg",
    },
    {
      title: "Ania Czarniecka - specjalista podolog",
      description:
        "wykonuje zabiegi pielęgnacyjne oraz lecznicze w schorzeniach stóp i paznokci.",
      imgLink: "../img/team/ania.jpg",
    },
    {
      title: "Małgorzata Królikowska - kosmetolog",
      description: "wykonuje zabiegi z zakresu kosmetologii  i laseroterapii.",
      imgLink: "../img/team/krolikowska.jpg",
    },
    {
      title:
        "Urszula Łapińska - absolwentka Uniwersytetu Medycznego we Wrocławiu na kierunku lekarsko-dentystycznym oraz studiów podyplomowych na kierunku medycyna estetyczna dla lekarzy w Śląskiej Wyższej Szkole Medycznej",
      description:
        "stale poszerza swoją wiedzę na wielu kursach praktycznych oraz sympozjach naukowych. Członek Polskiego towarzystwa Medycyny Estetycznej i Anti-Aging. Szczególnie interesuje się metodami profilaktyki starzenia się skóry, odbudowy utraconej objętości, wolumetrii i modelowania ust. Jej zabiegi charakteryzuje wysoka estetyka i naturalność.",
      imgLink: "../img/team/urszula.jpg",
    },
    {
      title: "Agnieszka Ulma - recepcjonistka, asystentka",
      description: "",
      imgLink: "../img/team/ulma.jpg",
    },
  ],
  photos: [
    {
      src: "../img/team/zespol.jpg",
      width: 3,
      height: 2,
    },
    {
      src: "../img/team/luiza_gabinet_1.jpg",
      width: 2,
      height: 3,
    },
    {
      src: "../img/team/luiza_gabinet_2.jpg",
      width: 3,
      height: 2,
    },
    {
      src: "../img/team/luiza_zabieg_1.jpg",
      width: 3,
      height: 2,
    },
    {
      src: "../img/team/luiza_zabieg_2.jpg",
      width: 3,
      height: 4,
    },
    {
      src: "../img/team/luiza-2.jpg",
      width: 2,
      height: 3,
    },
    {
      src: "../img/team/klinika-2.jpg",
      width: 3,
      height: 2,
    },
    {
      src: "../img/team/klinika-3.jpg",
      width: 3,
      height: 2,
    },
    {
      src: "../img/team/zabieg_4.jpg",
      width: 3,
      height: 2,
    },
    {
      src: "../img/team/zabieg_6.jpg",
      width: 3,
      height: 2,
    },
    {
      src: "../img/team/sesja_1.jpg",
      width: 3,
      height: 2,
    },
  ],
}
