export const posts = [
  {
    id: 4,
    name:
      "zabiegi-z-naruszeniem-ciaglosci-skory-tylko-u-lekarza-medycyny-estetycznej-wywiad",
    title:
      "Zabiegi z naruszeniem ciągłości skóry tylko u lekarza medycyny estetycznej - wywiad",
    date: "04.10.2022",
    short_description:
      "Dowiedz się, dlaczego na zabiegi z przerwaniem ciągłości naskórka warto wybrać się do renomowanej kliniki medycyny estetycznej. Zapraszamy do przeczytania rozmowy z dr n. med. Luizą Balicką Adamik",
  },
  {
    id: 3,
    name: "vi-charytatywny-bal-fundacji-mukohelp",
    title: "VI charytatywny bal fundacji Mukohelp",
    date: "21.02.2022",
    short_description:
      "W sobotę 5 lutego odbył się VI charytatywny bal fundacji Mukohelp. Fundacja ma pod swoją opieką dzieci cierpiące na nieuleczalną chorobę mukowiscydozę oraz ich opiekunów.",
  },
]
