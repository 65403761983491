export const site = {
  title: "Balicka Clinic",
  description:
    "Zapraszamy do zapisu w naszej klinice. Medycyna estetyczna min. biostymulatory, nici, lipoliza iniekcyjna, termolifting i nie tylko.",
  keywords: [
    "klinika medycyny estetycznej",
    "cennik",
    "usuwanie zmarszczek",
    "Jarosław",
    "Balicka",
    "toksyna botulinowa",
    "mezoterapia igłowa",
  ],
}
