export const clinic = {
  photos: [
    {
      src: "../img/clinic/logo.png",
      width: 2,
      height: 3,
    },
    {
      src: "../img/clinic/poczekalnia.jpg",
      width: 3,
      height: 4,
    },
    {
      src: "../img/clinic/gabinet.jpg",
      width: 3,
      height: 2,
    },
    {
      src: "../img/clinic/recepcja.jpg",
      width: 3,
      height: 4,
    },
    {
      src: "../img/clinic/certyfikaty.jpg",
      width: 3,
      height: 4,
    },
    {
      src: "../img/clinic/fotel_1.jpg",
      width: 4,
      height: 3,
    },
    {
      src: "../img/clinic/fotel_2.jpg",
      width: 3,
      height: 4,
    },
    {
      src: "../img/clinic/lazienka.jpg",
      width: 3,
      height: 4,
    },
    {
      src: "../img/clinic/lustro.png",
      width: 3,
      height: 4,
    },
    {
      src: "../img/clinic/świeczka.jpg",
      width: 3,
      height: 4,
    },
  ],
}
